import React, { useEffect, useState } from 'react';
import './UserStat.css'; // Import custom CSS
import { useNavigate, useLocation } from 'react-router-dom';
const UserStatistics = () => {
    const [data, setData] = useState({ total_users: 0, new_users: 0 });
    const [error, setError] = useState('');

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const fetchUserStatistics = async () => {
            try {
                const response = await fetch('https://fortunecm.icu/earncoin/api-php/add.php');
                const result = await response.json();

                if (result.error) {
                    setError(result.error);
                } else {
                    setData(result);
                }
            } catch (err) {
                setError('Error fetching data');
            }
        };

        fetchUserStatistics();
    }, []);

    return (
        <div className="container">
            <h1>User Statistics</h1>
            <div className="card">
                {error && <div className="alert">{error}</div>}
                <h4>Total Users: <span className="badge">{data.total_users}</span></h4>
                <h4>New Users (last 30 days): <span className="badge">{data.new_users}</span></h4>
            </div>
            <button onClick={()=> navigate('/task')}>Back</button>
        </div>
    );
};

export default UserStatistics;
