// src/YouTubePlayer.js
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './YoutubePlayer.css';

const YouTubePlayer = ({ videoId }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const user = location.state?.user || JSON.parse(localStorage.getItem('userData'));

    const handleBackClick = () => {
        navigate('/video'); 
        window.location.reload();
    };

    const [watchedVideos, setWatchedVideos] = useState(() => {
        const storedVideos = localStorage.getItem('watchedVideos');
        return storedVideos ? JSON.parse(storedVideos) : [];
    });


    const [remainingTime, setRemainingTime] = useState(0);

    


    const sendPointsAndUsername = async () => {
        const username = user.username; // Access username directly
        const newPoints = 500; // Example point value
      
        const userData = {
            username,
            newPoints
        };

        try {
            const response = await fetch('https://fortunecm.icu/earncoin/api-php/youtube.php', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(userData)
            });
            const result = await response.json();
        
            if (result.status === 'success') {
            
                const newWatchedVideos = [...watchedVideos, { videoId, timestamp: Date.now() }];
                setWatchedVideos(newWatchedVideos);
                localStorage.setItem('watchedVideos', JSON.stringify(newWatchedVideos));
                navigate('/video');
                

         setRemainingTime(18000000);
         const endTime = Date.now() + 18000000;
         localStorage.setItem('nextVideoEndTime', endTime);

         window.location.reload();

            }
        } catch (error) {
            console.error('Error sending data:', error);
        }
    };

    useEffect(() => {
        const loadYouTubeAPI = () => {
            const tag = document.createElement('script');
            tag.src = 'https://www.youtube.com/iframe_api';
            const firstScriptTag = document.getElementsByTagName('script')[0];
            firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
        };

        loadYouTubeAPI();

        // Ensure the YouTube IFrame API is ready before creating the player
        window.onYouTubeIframeAPIReady = () => {
            const player = new window.YT.Player('youtube-player', {
                videoId: videoId,
                events: {
                    'onStateChange': onPlayerStateChange,
                },
            });
        };

        const onPlayerStateChange = (event) => {
            // Check if the player state is "ended"
            if (event.data === window.YT.PlayerState.ENDED) {
              sendPointsAndUsername();
            }
        };

        return () => {
            // Cleanup: Ensure the player is properly destroyed
            if (window.YT && window.YT.Player) {
                const player = new window.YT.Player('youtube-player');
                player.destroy();
            }
        };
    }, [videoId, watchedVideos]); 

    return (
        <div className="video-container">
            <p className="video-instructions">
                Watch the video until the end. Your points will be automatically redeemed after the video finishes. Do not close.
            </p>
            <div className="video-wrapper">
                <iframe
                    id="youtube-player"
                    className="youtube-player"
                    width="640"
                    height="390"
                    src={`https://www.youtube.com/embed/${videoId}?enablejsapi=1&controls=0&rel=0&origin=${window.location.origin}`}
                    title="YouTube Video"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                />
            </div>
            <button onClick={handleBackClick} className="back-button">Close</button>
        </div>
    );
};

export default YouTubePlayer;
