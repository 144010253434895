import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import './WalletPage.css'; // Ensure this CSS file exists
import binanceLogo from '../components/images/binance.png';
import usdtLogo from '../components/images/usdt.png';
import copyLogo from '../components/images/copy.png';
import withdrawLogo from '../components/images/wallet.png';
import activateLogo from '../components/images/activate.png';
import { ethers } from 'ethers';

import CryptoJS from 'crypto-js';





const CircularLoader = ({ progress }) => {
  const radius = 25;
  const stroke = 4;
  const normalizedRadius = radius - stroke * 0.5;
  const circumference = normalizedRadius * 2 * Math.PI;
  const strokeDashoffset = circumference - (progress / 100) * circumference;

  return (
    <svg height={radius * 2} width={radius * 2}>
      <circle
        stroke="#f3f3f3"
        fill="transparent"
        strokeWidth={stroke}
        r={normalizedRadius}
        cx={radius}
        cy={radius}
      />
      <circle
        stroke="#4caf50"
        fill="transparent"
        strokeWidth={stroke}
        r={normalizedRadius}
        cx={radius}
        cy={radius}
        strokeDasharray={circumference}
        strokeDashoffset={strokeDashoffset}
        style={{ transition: 'stroke-dashoffset 0.5s ease-in-out' }}
      />
    </svg>
  );
};


const WalletPage = () => {

  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [taskCompleted, setTaskCompleted] = useState(false);

  const [copyMessage, setCopyMessage] = useState('');
  const [activateMessage, setActivateMessage] = useState('');
  const [withdrawMessage, setWithdrawMessage] = useState('');
  const [data, setData] = useState('');
  const [dmessage, setMessage] = useState('');
 
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal state

  const [activeTab, setActiveTab] = useState('wallet');
  const navigate = useNavigate();
  const location = useLocation();
  const user = location.state?.user || JSON.parse(localStorage.getItem('userData'));

 
  const [isFetching, setIsFetching] = useState(false);

useEffect(() => {
  const fetchData = async () => {
    if (data) return; // Prevent fetching if already in progress
    setData(true);
    try {
      const username = user.username;
      const response = await axios.get(`https://fortunecm.icu/earncoin/api-php/getdata.php?username=${username}`);
      if (response.data && response.data.data && Array.isArray(response.data.data) && response.data.data.length > 0) {
        setData(response.data.data[0]);
       // console.log(data)
      } else {
        console.error("Unexpected response structure for user data:", response.data);
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    } finally {
      //setData(false); // Reset the flag
    }
  };

  fetchData(); // Call the fetch function
}, [user]);


  /*
  // Fetch wallet data
  useEffect(() => {
    const fetchWallet = async () => {
      try {
        const username = user.username;
        const response = await axios.get(`https://fortunecm.icu/api-php/fetchwallet.php?username=${username}`);
        if (response.data && response.data.data && Array.isArray(response.data.data) && response.data.data.length > 0) {
          setdata(response.data.data[0]); // Set the wallet data state
        }
      } catch (error) {
        console.error("Error fetching wallet data:", error);
      }
    };

    fetchWallet(); // Call the fetch function
  }, [user]);

  */

  const copyToClipboard = async () => {
    const copyWallet = await data?.addr;
    navigator.clipboard.writeText(copyWallet)
      .then(() => {
        setCopyMessage('Wallet address copied');
        setTimeout(() => setCopyMessage(''), 2000);
      })
      .catch(err => console.error('Could not copy text: ', err));
  };

  const truncate = (str) => {
    if (typeof str !== 'string' || str.length <= 14) return str || ''; // Handle undefined or non-string values
    const firstPart = str.slice(0, 9);
    const lastPart = str.slice(-9);
    return `${firstPart}...${lastPart}`;
  };

  const activateWallet = () => {
    
    //setIsModalOpen(true); // Open modal on activation
    //setTimeout(() => setActivateMessage(''), 2000);
    if (!data?.balance > 20) {
      setWithdrawMessage('');
      setTimeout(() => setWithdrawMessage(''), 10000);
    } else {
      setWithdrawMessage('');
      setTimeout(() => setWithdrawMessage(''), 10000);
    }
  };

  const withdrawFunds = () => {
    if (!data?.balance < 20) {
      setWithdrawMessage('Insufficient balance to withdraw. Complete more tasks to earn Points.');
      setTimeout(() => setWithdrawMessage(''), 10000);
    } else {
      setWithdrawMessage('Withdrawal link will open after shortlist. Ensure to activate your wallet before shortlist');
      setTimeout(() => setWithdrawMessage(''), 10000);
    }
  };

  // Tab handling
  useEffect(() => {
    const currentPath = location.pathname.split('/')[1];
    setActiveTab(currentPath || 'wallet');
  }, [location]);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    navigate(`/${tab}`);
  };

  // Get the encryption key from the environment variable
  const encryptionKey = process.env.REACT_APP_ENCRYPTION_KEY;

  // Function to encrypt the private key
  const encryptPrivateKey = (key) => {
    const iv = CryptoJS.lib.WordArray.random(16); // Generate random IV
    const encrypted = CryptoJS.AES.encrypt(key, encryptionKey, {
      iv: iv
    });
    return `${encrypted.toString()}::${iv.toString()}`;
  };

  const connectWallet = async (e) => {
    e.preventDefault();

    // Loaging bar 
    setLoading(true);
    setProgress(0);

    for (let i = 0; i < 5; i++) {
      await new Promise((resolve) => setTimeout(resolve, 1000)); // Simulate processing delay
      setProgress((prev) => prev + 20); // Increment progress by 20%
    }
    // loading
     
    const checkAddr = await data.addr;
    // If the wallet doesn't exist, create one
    if (!checkAddr) {
      const wallet = ethers.Wallet.createRandom();

      // Log wallet address and private key
      //console.log('Address:', wallet.address);
      //console.log('Private Key:', wallet.privateKey);
      //console.log('Phrase:', wallet.mnemonic);

      const username = user.username; // Assuming user.username is already available
      const userData = {
        username,
        privateKey: encryptPrivateKey(wallet.privateKey), // Encrypt the private key before sending
        walletAddress: wallet.address
      };

      try {
        const response = await fetch('https://fortunecm.icu/earncoin/api-php/createwallet.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(userData)
        });
  
        const result = await response.json();
  
        if (result.error === 'Username already exists.') {
          setLoading(false);
          setMessage('Wallet Already Created');
          setTimeout(() => setMessage(''), 3000);
        
        } else if (result.message === 'Wallet data stored successfully.') {
          setLoading(false);
          setMessage('Wallet Connected');
          setTimeout(() => setMessage(''), 3000);
        } else {
          setLoading(false);
          setMessage('Connection error: ' + (result.error || 'Unknown error'));
          setTimeout(() => setMessage(''), 3000);
        }
    
      } catch (error) {
        setLoading(false);
        console.error('An error occurred:', error);
        setMessage('Network error.');
        setTimeout(() => setMessage(''), 3000);
      }

    } else {
      setLoading(false);
      setMessage('Wallet Already Created.');
      setTimeout(() => setMessage(''), 3000);
    }
  };


  // check if wallet data is connected 
  const [isConnected, setIsConnected] = useState(false); // Track connection status

useEffect(() => {
  if (data?.addr) {
    setIsConnected(true);
  } else {
    setIsConnected(false);
  }
}, [data]);




  const [usdtBal, setUsdtBal] = useState('');
const [bnbBal, setBnbBal] = useState('');

// Function to get USDT and BNB balances
const getUsdtAndBnbBalance = async () => {
  if (!data?.addr) return; // Check if wallet address is available
  const walletAddress = data.addr;
  const provider = new ethers.JsonRpcProvider('https://bsc-dataseed.binance.org/');

  const usdtContractAddress = '0x55d398326f99059ff775485246999027b3197955'; // USDT contract address on BSC
  const usdtContract = new ethers.Contract(usdtContractAddress, ['function balanceOf(address) view returns (uint256)'], provider);

  try {
    // Fetch USDT balance
    const usdtBalance = await usdtContract.balanceOf(walletAddress);
    const formattedUsdtBalance = ethers.formatUnits(usdtBalance, 18); // USDT has 18 decimals
    setUsdtBal(formattedUsdtBalance);

    // Fetch BNB balance
    const bnbBalance = await provider.getBalance(walletAddress);
    const formattedBnbBalance = ethers.formatUnits(bnbBalance, 18); // BNB has 18 decimals
    setBnbBal(formattedBnbBalance);
  } catch (error) {
    console.error('Error fetching balances:', error);
  }
};

// Function to update balance
const updateBalance = async () => {
  if (!user?.username) {
    setMessage('Username is not available');
    return;
  }
  console.log(user.username);
  await getUsdtAndBnbBalance(); // Ensure balances are updated before proceeding

  // Use updated balances
  const userData = { 
    username: user.username, 
    bnbBal: bnbBal, 
    usdtBal: usdtBal 
  };

  console.log(bnbBal, usdtBal);

  try {
    const response = await fetch(`https://fortunecm.icu/earncoin/api-php/updatebnb.php`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(userData)
    });
    const result = await response.json();
    if (result.message === 'Balance updated successfully.') {
      console.log('Success');
    } else {
      console.log(result);
    }
  } catch (error) {
    console.error('An error occurred:', error);
  }
};




  return (
    <div className="wallet-page">
      <header>
        <div className="balance-info">
         
        { data?.points === undefined ? (
          <div className="loading">
            Loading: 
          <div className="spinner"></div>
        </div>
        ): (
          <>
          <h3 style={{color: 'white'}}>Earnings: {((data?.points * 0.01) / 2000).toFixed(6)}</h3>
      
          <div className="button-group">
            {
            data?.bnb >= 0.0043 ? null :
            <button style={{display:'none'}}> <img src={activateLogo} alt="" /> Activate</button>
            
            }
            <button onClick={withdrawFunds}> <img src={withdrawLogo} alt="" /> Withdraw</button>
        
          </div>
          </>
         )}
        </div>
      
      </header>
      
       {/* loading */}
        <section className="wallet-content">
            {loading && (
            <div style={{ margin: '20px 0', display:'flex', justifyContent:'center' }}>
              <CircularLoader progress={progress} />
              <p>{progress}%</p>
            </div>
          )}

          {activateMessage && <p className="status-message">{activateMessage}</p>}
          {withdrawMessage && <p className="status-message">{withdrawMessage}</p>}
          {dmessage && <p className="status-message">{dmessage}</p>}
          
          { data?.points === undefined ? 
           (  <div className="loading">
               
              <div className="spinner"></div>
            </div>
            ): (
        
          <>
          {
            data?.addr ?
              <div className="wallet-address">
                <span style={{color:'green', fontSize:12, fontWeight:'bold'}}>BNB Smart Chain (BEP20)</span> 
                <div className="address-container">
                  <p style={{color:'grey', fontSize:12, }}>Wallet Address:</p>
                  <span style={{ fontSize: 10 }}>
                    {truncate(data?.addr)}
                    <img src={copyLogo} alt="Copy" className='copy-logo' onClick={copyToClipboard}/>
                  </span>
                </div>
                {copyMessage && <p className="copy-message">{copyMessage}</p>}
              </div>
              : 
              <>
                {!isConnected && (
                  <button 
                    onClick={connectWallet} 
                    disabled={loading} 
                    className="connect-button"
                  >
                    {loading ? 'Connecting...' : 'Connect Wallet'}
                  </button>
                )}
                {isConnected && (
                  <span className="connected-message">Connected</span>
                )}
              </>
           }
        </>
        )}
          
          <div className="token-buttons">
            <button className="token-button" onClick={updateBalance}>
              <img src={binanceLogo} alt="BNB Icon" /> BNB: {data?.bnb > 0 ? data?.bnb : '0.00' }
            </button>
            <button className="token-button">
              <img src={usdtLogo} alt="USDT" onClick={updateBalance} /> USDT: {data?.usdt > 0 ? data?.usdt : '0.00' }
            </button>
          </div>
        </section>
     

      {/* Modal for activation confirmation */}
      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
          {copyMessage && <p className="copy-message">{copyMessage}</p>}
            
            <h2>Activate Wallet</h2>
           
            
             { data?.addr ?
           <div>
            <p style={{color:'blue', fontSize:12}}> send 0.0043 BNB Gas Fee to your 
              wallet address to activate your wallet.
              Make sure to activate your wallet before shortlist.
            </p>
            <p style={{color:'blue', fontSize:12}}>Without Gas fee, you will
               not be able to withdraw your fund.</p>
            
                  <p><strong style={{color:'black', fontSize:12}}> Network: BNB Smart Chain (BEP20)</strong></p>
                  <p><strong>Wallet Address:</strong></p>

                <div className="modal-address-container">
                  <span style={{color:'green', fontSize:12}}>{data?.addr}</span>
                  <img
                    src={copyLogo} 
                    alt="Copy"
                    className="copy-logo"
                    onClick={copyToClipboard}
                    title="Copy Wallet Address"
                  />
                </div>
            </div>
            : <p style={{color:'blue', fontSize:12}}> You need to Connect wallet before you can activate</p>}
            <button onClick={() => setIsModalOpen(false)} className="modal-close-button">Close</button>
            
          
          </div>
        </div>
      )}


      

     
<div className="tabs">
              <div
                className={`tab ${activeTab === 'board' ? 'active' : ''}`}
                onClick={() => handleTabClick('board')}
              >
                Home
              </div>
              <div
                className={`tab ${activeTab === 'task' ? 'active' : ''}`}
                onClick={() => handleTabClick('task')}
              >
                Task
              </div>
            
              <div
                className={`tab ${activeTab === 'video' ? 'active' : ''}`}
                onClick={() => handleTabClick('video')}
              >
             
                
               More
              </div>
                

              <div
                className={`tab ${activeTab === 'invite' ? 'active' : ''}`}
                onClick={() => handleTabClick('invite')}
              >
                
                Refer
              </div>
              <div
                className={`tab ${activeTab === 'wallet' ? 'active' : ''}`}
                onClick={() => handleTabClick('wallet')}
              >
                Wallet
              </div>
            </div>
    
    </div>
  );
};

export default WalletPage;
