import React, { useState} from 'react';
import './RegisterPage.css';
import { useNavigate, useLocation } from 'react-router-dom';
import countries from './CountriesList';


const RegisterPage = () => {
  const [fullname, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [invitecode, setInvitecode] = useState('');
  const [message, setMessage] = useState('');
  const [code, setCode] = useState('');
  const navigate = useNavigate();
  const location = useLocation();




  // country
  const [selectedCountry, setSelectedCountry] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  const handleCountryChange = (event) => {
    setSelectedCountry(event.target.value);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  // Filter countries based on search term
  const filteredCountries = countries.filter(country =>
    country.toLowerCase().includes(searchTerm.toLowerCase())
  );



  const handleSendOtp = async () => {
    
    if (email.length === 0 || !email) {
      setMessage('Email is required');
      setTimeout(() => setMessage(''), 2000);
      return
  }
   
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!emailPattern.test(email)) {
    setMessage('Invalid Email');
    setTimeout(() => setMessage(''), 2000);
      return 
  }

     try {
      const response = await fetch('https://fortunecm.icu/earncoin/api-php/send-otp.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({email}),
      });

      const result = await response.json();
      if (result.Message === 'Email is required.') {
        setMessage('Email is required.')
        setTimeout(()=> setMessage(''), 2000 )
      
       }else if (result.Message === 'Invalid email format.') {
          setMessage('Invalid email format.')
          setTimeout(()=> setMessage(''), 2000 )
        
        }else if (result.Message === 'insert') {
          setMessage('OTP Code has been sent to your email. Check your email')
          setTimeout(()=> setMessage(''), 5000 )
          

      }else if (result.Message === 'update') {
        setMessage('OTP Code has been sent to your email. Check your email')
        setTimeout(()=> setMessage(''), 5000 )
      
      }else {
        setMessage('Check your connection')
        setTimeout(()=> setMessage(''), 2000 )
      }
    } catch (error) {
      console.log(`An error occurred: ${error.message}`);
    }
  };

  // Regiter
  const handleSubmit = async (e) => {
    
   
    e.preventDefault();



    if (email.length === 0 || !email) {
      setMessage('Email is required');
      setTimeout(() => setMessage(''), 2000);
      return
    }
   
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!emailPattern.test(email)) {
    setMessage('Invalid Email');
    setTimeout(() => setMessage(''), 2000);
      return 
  }

  console.log(selectedCountry)

    const username = email.toLowerCase().split('@')[0];
    const userData = {
      
      fullname,
      email,
      username,
      whoinvite: invitecode,
      otp,
      country: selectedCountry,
    
    };


    try {
      const response = await fetch('https://fortunecm.icu/earncoin/api-php/signup.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(userData),
      });

      if (response) {
        const result = await response.json();
         
        if (result.message === 'Invalid email format.') {
          setMessage('Valid Email is required.')
          setTimeout(()=> setMessage(''), 2000 )
        }
        else if (result.message === 'Wrong OTP code.') {
          setMessage('Invalid OTP Code.')
          setTimeout(()=> setMessage(''), 2000 )
        }
        else if (result.message === 'Email or username already exists.') {
          setMessage('Email already exist. Login')
          setTimeout(()=> setMessage(''), 2000 )
        }
        else if (result.message === 'Data inserted successfully.') {
          //setMessage('Sucess')
          //setTimeout(()=> setMessage(''), 2000 )
          localStorage.setItem('authToken', result.token);
          localStorage.setItem('userData', JSON.stringify(result));
          navigate('/board', { state: { user: result } });
        } else {
          setMessage('Connection error');
          setTimeout(()=> setMessage(''), 2000 )
        }
      } else {
        setMessage('Registration failed: Try Later');
        setTimeout(()=> setMessage(''), 2000 )
      }
    } catch (error) {
      console.log(error);
    }

    
  };

  
  

  return (
    <div className="register-container">
      <h1>Signup</h1>

     
     
      {message && <label className="error-message">{message}</label>}
      
      <form onSubmit={handleSubmit}>
        
    
      <input
        type="text"
        className="search-input"
        placeholder="Search for a country..."
        value={searchTerm}
        onChange={handleSearchChange}
        hidden
      />
      <select className="country-dropdown" value={selectedCountry} onChange={handleCountryChange} required>
        <option value="">--Select a country--</option>
        {filteredCountries.map((country, index) => (
          <option key={index} value={country}>
            {country}
          </option>
        ))}
      </select>
     

        <label>Full Name:</label>
        <input
          type="text"
          value={fullname}
          onChange={(e) => setFullName(e.target.value)}
          placeholder='Full Name'
          required
        />

        <label >Email:</label>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder='Email'
          required
        />

        <div className="otp-container">
          <p>OTP Code:</p>
          <input
            type="text"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
            required
          />
          <button type="button" onClick={handleSendOtp}>Get OTP</button>
        </div>

        <label>Referral:</label>
        <input
          type="text"
          value={invitecode}
          onChange={(e) => setInvitecode(e.target.value)}
          placeholder="Enter referral code or leave blank"
        
        />

        <div className="button-container">
          <button type="submit" className="register-button"> Signup</button>
        </div>
      </form>
      <p className="login-prompt">
        Already have an account? <a href="/login">Login</a>
      </p>
    </div>
  );
};

export default RegisterPage;
