import React from 'react';
import './YoutubeSelection.css';

const YouTubeEmbed = ({ videoId }) => {
    return (
        <div style={{ textAlign: 'center' }}>
            <h1>Watch the Video</h1>
            <div className='task-container'>
                <iframe
                    width="560"
                    height="315"
                    src={`https://www.youtube.com/embed/${videoId}`} // Use videoId prop dynamically
                    title="YouTube video player"
                    frameBorder="0" // Use camelCase for React
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerPolicy="strict-origin-when-cross-origin"
                    allowFullScreen // Use camelCase for React build
                />
            </div>
        </div>
    );
};

export default YouTubeEmbed;
