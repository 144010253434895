import React, { useState } from 'react';
import './LoginPage.css';
import { useNavigate, useLocation } from 'react-router-dom';

const LoginPage = () => {
  
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [message, setMessage] = useState('');
  

  const navigate = useNavigate();
  const location = useLocation();

  
 

  const handleSendOtp = async () => {
    
    if (email.length === 0 || !email) {
      setMessage('Email is required');
      setTimeout(() => setMessage(''), 2000);
      return
  }
   
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!emailPattern.test(email)) {
    setMessage('Invalid Email');
    setTimeout(() => setMessage(''), 2000);
      return 
  }

     try {
      const response = await fetch('https://fortunecm.icu/earncoin/api-php/send-otp.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({email}),
      });

      const result = await response.json();
      if (result.Message === 'Email is required.') {
        setMessage('Email is required.')
        setTimeout(()=> setMessage(''), 2000 )
      
       }else if (result.Message === 'Invalid email format.') {
          setMessage('Invalid email format.')
          setTimeout(()=> setMessage(''), 2000 )
        
        }else if (result.Message === 'insert') {
          setMessage('OTP Code has been sent to your email. Check your email.')
          setTimeout(()=> setMessage(''), 5000 )
          

      }else if (result.Message === 'update') {
        setMessage('OTP Code has been sent to your email. Check your email.')
        setTimeout(()=> setMessage(''), 5000 )
      
      }else {
        setMessage('Check your connection')
        setTimeout(()=> setMessage(''), 2000 )
      }
    } catch (error) {
      console.log(`An error occurred: ${error.message}`);
    }
  };

  // Regiter
  const handleSubmit = async (e) => {

   
    
    e.preventDefault();

    if (email.length === 0 || !email) {
      setMessage('Email is required');
      setTimeout(() => setMessage(''), 2000);
      return
    }
   
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!emailPattern.test(email)) {
    setMessage('Invalid Email');
    setTimeout(() => setMessage(''), 2000);
      return 
  }

    const username = email.toLowerCase().split('@')[0];
    const userData = {
      email,
      username,
      otp
    };


    try {
      const response = await fetch('https://fortunecm.icu/earncoin/api-php/login.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(userData),
      });

      if (response) {
        const result = await response.json();
         console.log(result);
        if (result.message === 'Invalid email format.') {
          setMessage('Valid Email is required.')
          setTimeout(()=> setMessage(''), 2000 )
        }
        else if (result.message === 'Wrong OTP code.') {
          setMessage('Invalid OTP Code.')
          setTimeout(()=> setMessage(''), 2000 )
        }
        else if (result.message === 'Email or username does not exist.') {
          setMessage('Email already exist. Login')
          setTimeout(()=> setMessage(''), 2000 )
        }
        else if (result.message === 'Login successful.') {
          setMessage('Sucess')
          setTimeout(()=> setMessage(''), 2000 )
          localStorage.setItem('authToken', result.token);
          localStorage.setItem('userData', JSON.stringify(result));
          navigate('/board', { state: { user: result } });
        } else {
          setMessage('Connection error');
          setTimeout(()=> setMessage(''), 2000 )
        }
      } else {
        setMessage('Registration failed: Try Later');
        setTimeout(()=> setMessage(''), 2000 )
      }
    } catch (error) {
      setMessage(`An error occurred`);
    }

    
  };


  return (
    <div className="register-container">
      <h1>Login</h1>
      
      {message && <label className="error-message">{message}</label>}
      <form onSubmit={handleSubmit}>
       
        <label>Email:</label>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />

        <div className="otp-container">
          <p>OTP Code:</p>
          <input
            type="text"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
            required
          />
          <button type="button" onClick={handleSendOtp}>Get OTP</button>
        </div>

       

        <div className="button-container">
          <button type="submit" className="register-button">Login</button>
        </div>
      </form>
      <p className="login-prompt">
        Dont have an account? <a href="/register"> Signup</a>
      </p>
    </div>
  );
};

export default LoginPage;
