import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import './TaskPage.css';
import youtubeLogo from '../components/images/youtube2.png';
import telegramLogo from '../components/images/telegram.png';
import tiktokLogo from '../components/images/tiktok.png';

const GridComponent = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [modalUrl, setModalUrl] = useState('');
  const [redeemCode, setRedeemCode] = useState('');
  const [message, setMessage] = useState('');
  const [data, setData] = useState('');
  const [visibleItemIndex, setVisibleItemIndex] = useState(null);
  const [activeTab, setActiveTab] = useState('board');
  const [linkCode, setLinkCode] = useState('');
  const [isClaimed, setIsClaimed] = useState(false); // Track if the item has been claimed

  const navigate = useNavigate();
  const location = useLocation();
  const user = location.state?.user || JSON.parse(localStorage.getItem('userData'));

  // Tab handling
  useEffect(() => {
    const currentPath = location.pathname.split('/')[1];
    setActiveTab(currentPath || 'Task');
  }, [location]);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    navigate(`/${tab}`);
  };

  const items = [
    {
      logo: youtubeLogo,
      title: 'Subscribe',
      description: 'Subscribe to YouTube Channel',
      url: 'https://www.youtube.com/channel/UCteCvHE8wVXehlIqB05uwzA?sub_confirmation=1',
      code: 19035224
    },
    {
      logo: telegramLogo,
      title: 'Telegram',
      description: 'Join Telegram Channel',
      url: 'https://t.me/fortuneclick',
      code: 847368
    },
    {
      logo: tiktokLogo,
      title: 'Tiktok',
      description: 'Follow us on TikTok',
      url: 'https://www.tiktok.com/@fortuneclick1280?is_from_webapp=1&sender_device=pc',
      code: 12343
    },

    {
      logo: youtubeLogo,
      title: 'YouTube',
      description: 'Watch Video',
      url: 'https://youtu.be/9AP2Sg8Uo-E',
      code: 1935224
    },
    {
      logo: youtubeLogo,
      title: 'YouTube',
      description: 'Watch Video',
      url: 'https://youtu.be/ezzv8HERYMU',
      code: 567394
    },
    {
      logo: youtubeLogo,
      title: 'YouTube',
      description: 'Watch Video',
      url: 'https://youtu.be/EVu2NHnFIFc',
      code: 5463372
    },
    {
      logo: youtubeLogo,
      title: 'YouTube',
      description: 'Watch Video',
      url: 'https://youtu.be/Fy1GVweJHVA',
      code: 65746479
    },
    {
      logo: youtubeLogo,
      title: 'YouTube',
      description: 'Watch Video',
      url: 'https://youtu.be/Ou--k7i3dUM',
      code: 19245292
    },
    {
      logo: youtubeLogo,
      title: 'YouTube',
      description: 'Watch Video',
      url: 'https://youtu.be/OoZybQmHHGA',
      code: 27839591
    },
    {
      logo: youtubeLogo,
      title: 'YouTube',
      description: 'Watch Video',
      url: 'https://youtu.be/PicmvEHz2W0',
      code: 43627729
    },
    {
      logo: youtubeLogo,
      title: 'YouTube',
      description: 'Watch Video',
      url: 'https://youtu.be/xJW5OX9ZEU4',
      code: 54635310
    },
    {
      logo: youtubeLogo,
      title: 'YouTube',
      description: 'Watch Video',
      url: 'https://youtu.be/RF8GKH-ixiQ',
      code: 86847321
    },
    {
      logo: youtubeLogo,
      title: 'YouTube',
      description: 'Watch Video',
      url: 'https://youtu.be/tfmcPAHLhpg',
      code: 65746382
    },
    {
      logo: youtubeLogo,
      title: 'YouTube',
      description: 'Watch Video',
      url: 'https://youtu.be/yos2fQ38kkg',
      code: 45219773
    },


    // Add more items here...
  ];

  const [claimedCodes, setClaimedCodes] = useState(() => {
    const storedCodes = localStorage.getItem('claimedCodes');
    return storedCodes ? new Set(JSON.parse(storedCodes)) : new Set();
  });

  const findNextAvailableItem = () => {
    for (let i = 0; i < items.length; i++) {
      if (!claimedCodes.has(items[i].code)) {
        return i; // Return the index of the first available item
      }
    }
    return null; // No available items
  };

  useEffect(() => {
    const now = Date.now();
    let nextDisplayKey = null;

    // Check for next display time in local storage
    claimedCodes.forEach((code) => {
      const displayTime = localStorage.getItem(`nextDisplay_${code}`);
      if (displayTime && now < displayTime) {
        const remainingTime = displayTime - now;
        nextDisplayKey = code;
        // Set a timeout for remaining time
        setTimeout(() => {
          const nextItemIndex = findNextAvailableItem();
          if (nextItemIndex !== null) {
            setVisibleItemIndex(nextItemIndex);
            setIsClaimed(false); // Reset claimed state after waiting
          }
        }, remainingTime);
      }
    });

    // If no next display time is found, check for the next available item immediately
    if (!nextDisplayKey) {
      const nextItemIndex = findNextAvailableItem();
      if (nextItemIndex !== null) {
        setVisibleItemIndex(nextItemIndex);
      }
    }
  }, [claimedCodes]);

  const handleOpenModal = (index) => {
    const item = items[index];
    if (item) {
      setModalTitle(item.title);
      setModalContent(item.description);
      setModalUrl(item.url);
      setModalOpen(true);
      setLinkCode(item.code);
      setRedeemCode('');
    }
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setModalTitle('');
    setModalContent('');
    setModalUrl('');
    setLinkCode('');
    setRedeemCode('');
  };

  const openLink = async () => {
    const username = user?.username;
    const userLink = { username, linkCode };

    try {
      const response = await fetch('https://fortunecm.icu/earncoin/api-php/link.php', {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(userLink),
      });
      const result = await response.json();
      if (result.status === 'success') {
        console.log('Link opened successfully');
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  const handleClaimReward = async (e) => {
    e.preventDefault();

    if (claimedCodes.has(redeemCode)) {
      setMessage('Code has already been claimed.');
      setTimeout(() => setMessage(''), 3000);
      return;
    }
  
    const username = await user.username;
    const newPoints = 500; // Example point value
  
    const userData = {
      username,
      redeemCode,
      modalUrl,
      newPoints
    };
  
    try {
      const response = await fetch('https://fortunecm.icu/earncoin/api-php/redeem.php', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(userData)
      });
      const result = await response.json();
  
      if (result.status === 'success') {

    const newClaimedCodes = new Set(claimedCodes);
    newClaimedCodes.add(items[visibleItemIndex].code);
    setClaimedCodes(newClaimedCodes);
    localStorage.setItem('claimedCodes', JSON.stringify([...newClaimedCodes]));

    const newDisplayTime = Date.now() + 120000; // 2 minutes later
    localStorage.setItem(`nextDisplay_${items[visibleItemIndex].code}`, newDisplayTime);

    setRedeemCode('');
    handleCloseModal(); // Close modal after claiming
    setIsClaimed(true); // Mark the item as claimed

    // Automatically find the next available item after 2 minutes
    setTimeout(() => {
      const nextItemIndex = findNextAvailableItem();
      if (nextItemIndex !== null) {
        setVisibleItemIndex(nextItemIndex);
        setIsClaimed(false); // Reset claimed state after waiting
      }
    }, 120000); // Show next item after 2 minutes

      setMessage('Reward Claimed');
        setTimeout(() => {
          setMessage('');
        }, 3000);
      } else {
        setMessage('Invalid Redeem Code');
        setTimeout(() => {
          setMessage('');
        }, 3000);
      }
  
    } catch (error) {
      console.log('An error occurred:', error);
    }
  };


  const renderGridItem = (index) => {
    if (index !== null && index < items.length) {
      const item = items[index];
      return (
        <div className="grid-item" onClick={() => handleOpenModal(index)} style={{ pointerEvents: isClaimed ? 'none' : 'auto' }}>
          <img className='grid-image' src={item.logo} alt={item.title} />
          <h3>{item.title}</h3>
          <p>{item.description}</p>
        </div>
      );
    }
    return null; // Return null if the index is invalid
  };

  return (
    <div className="task-container">
      <header className="header">
        <div className="user-stats">
          <span className="title-point">Points: {/* Your points logic here */}</span>
          <span className="title-task">Tasks Completed: {/* Your tasks logic here */}</span>
        </div>
        <div className="task-title">
          <span className="balance-label">Daily Task</span>
        </div>
        <span style={{ color: 'green' }}>{message}</span>
      </header>

      <div className="grid">
       
        
          {!isClaimed ? renderGridItem(visibleItemIndex) : 
          
          <p style={{textAlign:'center'}}>You have no task. check later</p>
          
          }
        
      </div>

      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal">
            <h2>{modalTitle}</h2>
            <p style={{ fontSize: 12 }}>{modalContent}</p>
            <p className="mo-content">
              {modalTitle === 'Telegram' ? '' : `Click the open link button to ${modalContent}. 
                Use the code on the ${modalTitle} Channel/Video to redeem your reward.`}
            </p>

            <button className="modal-button" onClick={() => {
              window.open(modalUrl, '_blank');
              openLink();
            }}>
              Open Link
            </button>
            <span style={{ color: 'blue' }}>Do not enter an invalid code. Make sure to watch the video to avoid account suspension.</span>
            <span style={{ color: 'red' }}>{message}</span>
            <form onSubmit={handleClaimReward}>
              <input
                type="text"
                placeholder="Enter redeem code"
                value={redeemCode}
                onChange={(e) => setRedeemCode(e.target.value)}
                className="redeem-input"
                required
              />
              <button type="submit" className="modal-button claim-button">Claim Reward</button>
            </form>
            <button className="modal-button-close" onClick={handleCloseModal}>
              Close
            </button>
          </div>
        </div>
      )}

        <div className="tabs">
          <div className={`tab ${activeTab === 'board' ? 'active' : ''}`} onClick={() => handleTabClick('board')}>
            Home
          </div>
          <div className={`tab ${activeTab === 'task' ? 'active' : ''}`} onClick={() => handleTabClick('task')}>
            Task
          </div>
          <div className={`tab ${activeTab === 'invite' ? 'active' : ''}`} onClick={() => handleTabClick('invite')}>
            Refer
          </div>
          <div className={`tab ${activeTab === 'wallet' ? 'active' : ''}`} onClick={() => handleTabClick('wallet')}>
            Wallet
          </div>
          
        </div>
    </div>
  );
};

export default GridComponent;
